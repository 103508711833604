.Form {
  display: flex;
  flex-direction: column;
}

.date-group {
  display: flex;
  justify-content: space-between;
}

.date-element {
  display: flex;
  flex-direction: column;
  width: 10em;
}

label {
  margin: 1em 0;
}

.App-form:first-child {
  margin-top: 0;
}

input {
  border: 0;
  border-radius: 5px;
  height: 52px;
  background-color: #e8e8e8;
  text-align: center;
  font-size: 1em;
}

button {
  border: 0;
  box-shadow: 0;
  margin: 0 auto;
  margin-top: 2em;
  width: 15em;
  background-color: #6ed796;
  text-decoration: none;
  height: 58px;
  padding: 0px 40px;
  font-weight: bold;
  font-size: 1.5625rem;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;
}
