.App {
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  font-size: 1.75em;
  color: #000;
  width: 720px;
  margin: 0 auto;
}

.App-logo {
  width: 15em;
}
