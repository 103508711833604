.Result {
  font-size: 14px;
  color: #1d99cd;
  cursor: pointer;
  margin-top: 1em;
}

.clicked::after {
  content: "🤘";
}
